/* services.css */

.services {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Set height to 100% of viewport height, adjust if needed */
  }

  .services-content {
    height: 80%; 
  }
  
  
  .services h1 {
    text-align: center;
    display: flex;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  