/* navbar.css */

.navbar {
  padding: 20px;
}

.navbar ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start; /* Align items to the start */
}

.navbar li {
  margin-right: 35px; /* Reduce the margin between list items */
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 30px; /* Adjust font size */
  position: relative;
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .navbar {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .navbar a {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
