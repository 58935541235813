/* mobilenavbar.css */

.mobilenavbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    position: relative;
    z-index: 101; /* Set a higher z-index value */
  }
  
  .menu-icon {
    cursor: pointer;
    font-size: 24px;
    color: white;
    padding: 10px
  }
  
  .drawer {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 40%; /* Adjust the width as needed */
    background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
    padding: 20px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 100; /* Set a lower z-index value */
    align-items: center;
  }
  
  .drawer.open {
    transform: translateX(0);
  }
  
  .drawer a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 34px;
    margin-bottom: 25px; /* Add margin between links */
  }
  
  .drawer a:last-child {
    margin-bottom: 0; /* Remove margin from the last link */
  }

  .menu-icon.hidden {
    display: block; /* Change display to block or inline-block based on your layout */
    visibility: hidden;
  }