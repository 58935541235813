/* privacyPolicy.css */

.privacy-policy {
  color: white;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy-policy-container {
  height: 100%;
  width: 80%; /* Adjust the width as needed */
  display: flex;
  justify-content: center;
  overflow-y: auto; /* Add this line to enable vertical scrolling */
}

.privacy-policy-content {
  width: 100%; /* Adjust the width as needed */
  height: 100%;
  padding: 20px;
}

.privacy-policy-title {
  color: white;
  font-size: 24px;
  text-align: center; /* Center text within the container */
}