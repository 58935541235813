/* contact.css */

.contact {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Set the text color to white */
  background-color: rgba(0, 0, 0, 0.7); /* Black transparent background */
}

.contact-content {
  display: flex;
  flex-direction: column; /* Adjusted to column layout for vertical alignment */
  text-align: center;
  justify-content: center;
  align-items: center; /* Center vertically */
}

.contact-content h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.contact-content p {
  font-size: 34px;
  margin-bottom: 15px;
}

.contact-content a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white; /* Set the text color to white */
  font-size: 1.2rem;
}

.discord-icon {
  height: 200px;
  margin-right: 8px;
}

.eiffel-icon{
  height: 20px;
  display: none;
}