/* footer.css */

.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjusted to take full height of the footer-section */
}

.footer-content p {
  margin: 10px; /* Remove default margin */
}

.footer-content a {
  color: white;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content p {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .footer-content p {
    font-size: 12px;
  }
}
