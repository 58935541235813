/* date.css */

.top-right-date {
  position: absolute;
  top: 40px;
  right: 0px;
  display: flex;
  align-items: center;
  z-index: 102;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 24px; /* Adjust the font size to match Navbar links */
  margin-right: 30px; /* Adjust the margin to match Navbar links */
  position: relative;
}

/* Style for the active button (applies the underline) */
button.active {
  border-bottom: 4px solid white; /* Adjust the border-bottom to match Navbar links */
}

/* Add media queries for specific adjustments on mobile devices if needed */
@media (max-width: 768px) {
  .top-right-date {
    top: 20px;
  }
}
