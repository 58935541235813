/* App.css */

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif; /* Add your preferred font-family */
}

html, body {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.background-container {
  background-image: url('./assets/img/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  display: flex;
}

.navbar-section {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 10vh; /* 10% of the viewport height */
}

.content-section {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 80vh; /* 85% of the viewport height */
  overflow-y: auto; /* Enable vertical scrollbar */
  
  /* Hide scrollbar arrows and customize scrollbar for Webkit browsers */
  scrollbar-width: thin;
  scrollbar-color: #111111 #7a7a7a;
}

.content-section::-webkit-scrollbar {
  width: 10px;
}

.content-section::-webkit-scrollbar-thumb {
  background-color: #7a7a7a;
}

.content-section::-webkit-scrollbar-track {
  background-color: #7a7a7a;
}

.footer-section {
  width: 100%;
  box-sizing: border-box;
  margin-top: auto; /* Push the footer to the bottom */
  height: 10vh; /* 5% of the viewport height */
}

/* Add media queries for specific adjustments on mobile devices if needed */
@media (max-width: 768px) {
  .navbar-section,
  .content-section {
    padding: 10px;
  }
}
