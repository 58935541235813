/* home.css */

.images {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Set height to 100% of viewport height, adjust if needed */
}

.images img {
  max-width: 100%;
  max-height: 100%;
}
